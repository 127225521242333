export const Bio = {
  title: "Greetings, I am Bojan",
  roles: [
    "Web Development",
    "Learning about Tech",
    "Gaming"
  ],
  description:
    "Passionate software and web developer from Serbia\nInterested in Frontend development, embedded and AI",
  github: "https://github.com/Turtlebole",
  resume:
    "",
  linkedin: "https://www.linkedin.com/in/babicbojan/",
};

export const skills = [
  {
    title: "Frontend",
    skills: [
      {
        name: "Angular",
        image:
          "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/Angular-Dark.svg",
      },
      {
        name: "React Js",
        image:
          "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/React-Dark.svg",
      },
      {
        name: "Vue Js",
        image:
            "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/VueJS-Dark.svg",
      },
      {
        name: "Nuxt Js",
        image:
            "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/NuxtJS-Dark.svg",
      },
      {
        name: "Next Js",
        image:
          "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/NextJS-Dark.svg",
      },
      {
        name: "JavaScript",
        image:
          "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/JavaScript.svg",
      },
      {
        name: "TypeScript",
        image:
          "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/TypeScript.svg",
      },
      {
        name: "HTML",
        image: "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/HTML.svg",
      },
      {
        name: "CSS",
        image:
          "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/CSS.svg",
      },
      {
        name: "Bootstrap",
        image:
          "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/Bootstrap.svg",
      },
      {
        name: "Tailwind",
        image:
          "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/TailwindCSS-Dark.svg",
      },
     
    ],
  },
  {
    title: "Backend",
    skills: [
      {
        name: "C#",
        image:
          "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/CS.svg",
      },
      {
        name: "Java",
        image:
          "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/Java-Dark.svg",
      },
      {
        name: "Python",
        image:
          "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/Python-Dark.svg",
      },
      {
        name: "Go",
        image:
          "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/GoLang.svg",
      },
    ],
  },
  {
    title: "Database",
    skills: [
      {
        name: "MsSQL",
        image:
          "http://upload.wikimedia.org/wikipedia/de/8/8c/Microsoft_SQL_Server_Logo.svg",
      },
      {
        name: "MySQL",
        image:
          "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/MySQL-Dark.svg",
      },
      {
        name: "Postgresql",
        image: "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/PostgreSQL-Dark.svg",
      },
      {
        name: "MongoDB",
        image:
          "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/MongoDB.svg",
      },
      {
        name: "Firebase",
        image: "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/Firebase-Dark.svg",
      },
    ]
  },
];

export const projects = [
  {
    id: 1,
    title: "Laplacia",
    date: "2023 - Present",
    description: "Web application used to assist writers...",
    images: [
      "https://i.imgur.com/um67xkT.png",
      "https://i.imgur.com/example2.png",
      "https://i.imgur.com/example3.png"
    ],
    tags: [
      "C#",
      ".NET",
      "PostgreSQL",
      "Angular",
      "Docker",
      "DDD",
      "REST API",
      "Entity Framework"
    ],
    category: "Web",
    github: "https://github.com/ProjectLaplacia",
    webapp: "#",
    type: "Full Stack Application",
    role: "Lead Developer",
    status: "In Development",
    duration: "6+ months",
    additionalImages: [
      "https://i.imgur.com/um67xkT.png",
      // Add more image URLs when available
    ]
  },
  {
    id: 2,
    title: "Airbnb Clone",
    date: "2023",
    description: "A comprehensive fullstack clone of Airbnb...",
    images: [
      "https://i.imgur.com/um67xkT.png",
      "https://i.imgur.com/example2.png",
      "https://i.imgur.com/example3.png"
    ],
    tags: [
      "Go",
      "Angular",
      "MongoDB",
      "CassandraDB",
      "Docker",
      "Microservices",
      "REST API",
      "JWT Auth"
    ],
    category: "Web",
    github: "https://github.com/Turtlebole/airbnb-msa-mockup",
    webapp: "#",
    type: "Microservices Application",
    role: "Full Stack Developer",
    status: "Completed",
    duration: "3 months",
    additionalImages: [
      "https://i.imgur.com/um67xkT.png",
      // Add more image URLs when available
    ]
  },
  {
    id: 3,
    title: "Fitness Manager",
    date: "2023",
    description: "A comprehensive desktop application for fitness center management...",
    images: [
      "https://i.imgur.com/um67xkT.png",
      "https://i.imgur.com/example2.png",
      "https://i.imgur.com/example3.png"
    ],
    tags: [
      "C#",
      ".NET",
      "MicrosoftSQL",
      "Windows Forms",
      "Entity Framework",
      "LINQ",
      "Repository Pattern"
    ],
    category: "Desktop",
    github: "https://github.com/Turtlebole/fitness-center-manager",
    webapp: "#",
    type: "Desktop Application",
    role: "Backend Developer",
    status: "Completed",
    duration: "2 months",
    additionalImages: [
      "https://i.imgur.com/um67xkT.png",
      // Add more image URLs when available
    ]
  },
  {
    id: 4,
    title: "Project 4", // Update with actual project
    date: "2023",
    description: "Detailed description of the project goes here...Detailed description of the project goes here...Detailed description of the project goes here...Detailed description of the project goes here...Detailed description of the project goes here...Detailed description of the project goes here...Detailed description of the project goes here...Detailed description of the project goes here...Detailed description of the project goes here...Detailed description of the project goes here...Detailed description of the project goes here...Detailed description of the project goes here...Detailed description of the project goes here...Detailed description of the project goes here...Detailed description of the project goes here...Detailed description of the project goes here...Detailed description of the project goes here...",
    images: [
      "https://i.imgur.com/um67xkT.png",
      "https://i.imgur.com/example2.png",
      "https://i.imgur.com/example3.png"
    ],
    tags: [
      "C#",
      ".NET",
      "MicrosoftSQL",
      "Windows Forms",
      "Entity Framework",
      "LINQ",
      "Repository Pattern"
    ],
    category: "Web",
    github: "https://github.com/yourusername/project4",
    webapp: "#",
    type: "Full Stack Application",
    role: "Frontend Developer",
    status: "Completed",
    duration: "3 months",
    additionalImages: [
      "https://i.imgur.com/um67xkT.png",
      // Add more image URLs when available
    ]
  },
  {
    id: 5,
    title: "Project 5", // Update with actual project
    date: "2023",
    description: "Detailed description of the project goes here...",
    images: [
      "https://i.imgur.com/um67xkT.png",
      "https://i.imgur.com/example2.png",
      "https://i.imgur.com/example3.png"
    ],
    tags: [
     "C#",
      ".NET",
      "MicrosoftSQL",
      "Windows Forms",
      "Entity Framework",
      "LINQ",
      "Repository Pattern"
    ],
    category: "Web",
    github: "https://github.com/yourusername/project5",
    webapp: "#",
    type: "Web Application",
    role: "Full Stack Developer",
    status: "Completed",
    duration: "4 months",
    additionalImages: [
      "https://i.imgur.com/um67xkT.png",
      // Add more image URLs when available
    ]
  }
];