export const darkTheme = {
    bg: "#1A0F2E",            // Deep purple background
    bgLight: "#2D1B4A",       // Medium purple
    primary: "#5C3884",       // Bright purple
    text_primary: "#F8F8F8",  // Almost white
    text_secondary: "#E6D5FF", // Light purple text
    text_skills: "#D4B675",    // Golden brown (scarf color)
    card: "#1A0F2E",          // Deep purple
    card_light: "#1A0F2E",    // Deep purple for navbar and top button
    card_border: "#2D1B4A",   // Border color for cards and posts
    button: "#5C3884",        // Bright purple
    white: "#FFFFFF",
    black: "#000000",
    colored_detail: "#D4B675", // Golden brown (scarf color)
    highlighted_svg: "#D4B675", // Golden brown (scarf color)
    buttonGradient: "linear-gradient(180deg, #2D1B4A 0%, #1A0F2E 100%)",
    top_button_bg: "#1A0F2E"  // Added for top button background
};

export const lightTheme = {
    bg: "#F4EEFF",            // Very light purple
    bgLight: "#FFFFFF",
    primary: "#5C3884",       // Bright purple
    text_primary: "#1A0F2E",  // Deep purple for text
    text_secondary: "#2D1B4A", // Medium purple for secondary text
    text_skills: "#5C3884",    // Bright purple
    card: "#FFFFFF",
    card_light: "#1A0F2E",    // Deep purple for navbar and top button
    card_border: "#E6D5FF",   // Border color for cards and posts
    button: "#5C3884",        // Bright purple
    white: "#FFFFFF",
    black: "#000000",
    colored_detail: "#D4B675", // Golden brown (scarf color)
    highlighted_svg: "#D4B675", // Golden brown (scarf color)
    buttonGradient: "linear-gradient(180deg, #2D1B4A 0%, #1A0F2E 100%)",
    top_button_bg: "#1A0F2E"  // Added for top button background
};

